// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormManageContract } from '../../forms/FormManageContract/FormManageContract';

// props
export interface DialogAddContractProps {
	isOpen: boolean;
	onClose: () => void;
}

export const DialogAddContract = ({ isOpen, onClose }: DialogAddContractProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
				},
				{
					children: t('addContract'),
					form: 'form-manage-contract',
					loading: isLoading,
					type: 'submit',
					variant: 'contained',
				},
			]}
			hasClose={true}
			isOpen={isOpen}
			onClose={onClose}
			title={{
				children: t('addContract'),
				variant: 'h3',
			}}>
			<FormManageContract onClose={onClose} setIsLoading={setIsLoading} />
		</DialogActions>
	);
};
