// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useEffect, useState } from 'react';

// types
import {
	Entity,
	EntityLoanPayload,
	EntitySubtype,
	ErrorCause,
	FormField,
	Loan,
	MetadataField,
	MetadataValue,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner, ContextUser } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

// fields
import { DynamicField } from '../../fields/DynamicField/DynamicField';
import { Input } from '../../fields/Input/Input';
import { RadioBoxes } from '../../fields/RadioBoxes/RadioBoxes';
import { Select } from '../../fields/Select/Select';

// icons
import {
	PeopleOutlineOutlined as IconPeople,
	PersonOutlined as IconPerson,
} from '@mui/icons-material';

// props
export interface FormAddBeneficiaryProps {
	onClose?: () => void;
	refetchBeneficiaries?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddBeneficiary = ({
	onClose,
	refetchBeneficiaries,
	setIsLoading,
}: FormAddBeneficiaryProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [beneficiaryType, setBeneficiaryType] = useState<EntitySubtype>('INDIVIDUAL');
	const [entities, setEntities] = useState<Entity[]>([]);
	const [entity, setEntity] = useState('');
	const [error, setError] = useState<string | null>(null);
	const [fields, setFields] = useState<FormField[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);

	// vars
	const formId = 'form-add-beneficiary';
	const isWfa = user?.role === 'WFA';
	const userEntity = user?.entity ? (user.entity as Entity) : null;
	const userEntityId = typeof user?.entity === 'number' ? user?.entity : user?.entity?.id;

	// fetch entities
	useFetch({
		isEnabled: Boolean(!userEntity),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&type=PIU,SUB_PIU&fields=id,name`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// fetch loans
	let urlLoans = `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,idWbg,status`;

	if (isWfa && entity) {
		urlLoans += `&entity=${entity}`;
	}

	const { fetchRequest: fetchLoans } = useFetch({
		isLazy: true,
		url: urlLoans,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch form
	const { fetchRequest: fetchForm } = useFetch({
		isLazy: true,
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/forms?entity=${
			userEntityId || entity
		}&name=BENEFICIARY_${beneficiaryType}&limit=1&orderBy=modified&populate=user&status=PUBLISHED`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data[0]) {
				const fields: FormField[] = res.data[0].fields.map((field: FormField) => {
					const fieldsDefault = ['idExternal', 'name'];
					const fieldsUser = ['email', 'firstName', 'lastName', 'phone'];

					let name = field.name;

					if (!fieldsDefault.includes(field.name)) {
						if (fieldsUser.includes(field.name)) {
							name = `user-${field.name}`;
						} else {
							name = `metadata-${field.name}`;
						}
					}

					return {
						...field,
						name,
					};
				});

				setFields(fields);
			}
		},
	});

	// options
	const optionsEntities = entities.map((entity) => {
		return {
			label: entity.name,
			value: String(entity.id),
		};
	});

	const optionsLoans = loans.map((loan) => {
		return {
			disabled: loan.status === 'DISABLED',
			label: loan.idWbg,
			value: loan.id,
		};
	});

	// inputs
	const radioInputs = [
		{
			icon: <IconPerson />,
			label: t('individual'),
			value: 'INDIVIDUAL',
		},
		{
			icon: <IconPeople />,
			label: t('organization'),
			value: 'ORGANIZATION',
		},
	];

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// set loader
		setIsLoading(true);

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// define loans
			const beneficiaryLoans: EntityLoanPayload[] = [];
			const entryLoans = (entries.loans as string).split(',').filter((val) => val !== 'all');

			// for each loan entry
			for (let i = 0; i < entryLoans.length; i++) {
				// add loan
				beneficiaryLoans.push({
					id: Number(entryLoans[i]),
					parent: Number(userEntityId || entity),
				});
			}

			// define metadata
			const metadata: MetadataField[] = [];

			// for each field
			for (let i = 0; i < fields.length; i++) {
				const field = fields[i];

				let value = entries[field.name] as MetadataValue;

				// define value by field type
				if (field.type === 'CHECKBOX') {
					value = Boolean(entries[field.name]);
				} else if (field.type === 'DATE') {
					value = new Date(value as Date | string);
				} else if (field.type === 'NUMBER') {
					value = Number(value);
				}

				// add metadata key
				if (field.name.includes('metadata-')) {
					const key = field.name.replace('metadata-', '');
					metadata.push({
						label: field.label,
						name: key,
						type: field.type,
						value,
					});
				}
			}

			const fetchAddBeneficiary = await fetch(`${process.env.REACT_APP_API_URL}/entities`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify({
					idExternal: entries[`idExternal`],
					isKycVerified: true,
					loans: beneficiaryLoans,
					metadata,
					name: entries[`name`] || `${entries[`user-firstName`]} ${entries[`user-lastName`]}`,
					project: project?.id,
					subtype: beneficiaryType,
					type: 'BENEFICIARY',
					users: [
						{
							email: entries[`user-email`],
							firstName: entries[`user-firstName`],
							lastName: entries[`user-lastName`],
							phone: entries['user-phone'],
							project: project?.id,
							role: 'GENERAL',
						},
					],
				}),
			});
			const resAddBeneficiary = await fetchAddBeneficiary.json();

			if (resAddBeneficiary.error) {
				throw new Error(resAddBeneficiary.error.message, {
					cause: {
						details: resAddBeneficiary.error.details,
						id: resAddBeneficiary.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch
			if (refetchBeneficiaries) {
				refetchBeneficiaries();
			}

			// set success banner
			let entityName = `${entries[`user-firstName`]} ${entries[`user-lastName`]}`;

			if (beneficiaryType === 'ORGANIZATION') {
				entityName = String(entries[`name`]);
			}

			setBanner({
				hasClose: true,
				message: t('alert.beneficiaryCreated', {
					name: entityName,
				}),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause.id === 'COUNTRY_CODE_INVALID') {
				message = t('error.countryCodeInvalid');
			}

			if (cause.id === 'ENTITY_DUPLICATE') {
				message = t('error.beneficiaryDuplicate', { idExternal: entries.idExternal });
			}

			if (cause.id === 'USER_DUPLICATE') {
				message = t('error.userDuplicate', { email: cause.details.email });
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	// handlers
	const handleChangeBeneficiaryType = (
		e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
		value: string
	) => {
		setBeneficiaryType(value as EntitySubtype);
	};

	// fetch form and loans when beneficiary type changes
	useEffect(() => {
		if (!isWfa || (isWfa && entity)) {
			fetchForm();
			fetchLoans();
		}
	}, [beneficiaryType, entity, fetchForm, fetchLoans, isWfa]);

	return (
		<form id={formId} name="formAddBeneficiary" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{isWfa && (
					<Grid item xs={6}>
						<Select
							id={`${formId}-entity`}
							label={t('entity')}
							name="entity"
							onChange={(e) => {
								const { value } = e.target as HTMLInputElement;
								fetchLoans();
								setEntity(value);
							}}
							options={optionsEntities}
							required={true}
							value={entity}
						/>
					</Grid>
				)}
				<Grid item xs={isWfa ? 6 : 12}>
					<Select
						disabled={isWfa && !entity}
						hasAllOption={true}
						id={`${formId}-loans`}
						label={t('financingSources')}
						multiple={true}
						name="loans"
						options={optionsLoans}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<RadioBoxes
						id={`${formId}-type`}
						defaultValue={beneficiaryType}
						inputs={radioInputs}
						label={t('type')}
						name="type"
						onChange={handleChangeBeneficiaryType}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box
						sx={(theme) => {
							return {
								backgroundColor: theme.palette.brand.grey[200],
								borderRadius: 1,
								padding: theme.spacing(2),
							};
						}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography fontWeight={600}>{t('userAccount')}</Typography>
								<Typography>{t('userAccount.intro')}</Typography>
							</Grid>

							<Grid item xs={12} lg={6}>
								<Input
									id={`${formId}-name`}
									label={t('name')}
									name="user-firstName"
									required={true}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Input id={`${formId}-last-name`} label={t('lastName')} name="user-lastName" />
							</Grid>
							<Grid item xs={12} lg={6}>
								<Input
									id={`${formId}-email`}
									label={t('email')}
									name="user-email"
									required={true}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Input id={`${formId}-phone`} label={t('phone')} name="user-phone" />
							</Grid>
						</Grid>
					</Box>
				</Grid>
				{fields.map((field, i) => {
					return (
						<Grid
							alignSelf={field.type === 'CHECKBOX' ? 'flex-end' : 'flex-start'}
							item
							key={`field-${field.name}-${i}`}
							xs={12}
							lg={6}>
							<DynamicField field={field} />
						</Grid>
					);
				})}
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={true}
								id={`${formId}-is-kyc-verified`}
								name="isKycVerified"
								required={true}
							/>
						}
						label={t('formAddBeneficiary.kycAml')}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
