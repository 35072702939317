// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useMemo, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import {
	Entity,
	FormField,
	Form,
	MetadataField,
	EntityPayload,
	MetadataValue,
	ErrorCause,
} from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// context
import { ContextBanner } from '../../../App.context';
import { ContextBeneficiaries } from '../../pages/Project/Project.context';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { DynamicField } from '../../fields/DynamicField/DynamicField';
import { Input } from '../../fields/Input/Input';

// ui
import { Loading } from '../../ui/Loading/Loading';

// props
export interface FormEditBeneficiaryProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormEditBeneficiary = ({ onClose, setIsLoading }: FormEditBeneficiaryProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { refetchBeneficiaries } = useContext(ContextBeneficiaries);

	// state
	const [beneficiary, setBeneficiary] = useState<Entity | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [form, setForm] = useState<Form | null>(null);
	const [searchParams] = useSearchParams();

	// params
	const id = searchParams.get('dialogBeneficiary');

	// vars
	const idForm = `form-edit-beneficiary`;
	const metadata = beneficiary?.metadata ? (beneficiary.metadata as MetadataField[]) : null;

	// fetch beneficiary
	const { isLoading: isLoadingBeneficiary } = useFetch({
		url: `${process.env.REACT_APP_API_URL}/entities/${id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBeneficiary(res.data[0]);
			}
		},
	});

	let formName = 'BENEFICIARY_INDIVIDUAL';

	if (beneficiary?.subtype === 'ORGANIZATION') {
		formName = 'BENEFICIARY_ORGANIZATION';
	}

	// fetch forms
	const { isLoading: isLoadingForm } = useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/forms?entity=${beneficiary?.loans?.[0]?.parent}&name=${formName}&status=PUBLISHED`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setForm(res.data[0]);
			}
		},
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		// set loading
		setIsLoading(false);

		try {
			const body: EntityPayload = {
				idExternal: String(entries.idExternal),
				metadata: [],
				name: String(entries.name),
			};

			const bodyMetadata = body.metadata as MetadataField[];

			// construct body using fields and entries
			for (let i = 0; i < fields.length; i++) {
				const field = fields[i];

				let value: MetadataValue = String(entries[field.name]);

				if (field.type === 'CHECKBOX') {
					value = Boolean(value);
				}

				if (field.type === 'NUMBER') {
					value = Number(value);
				}

				if (field.name.includes('metadata-')) {
					bodyMetadata.push({
						label: field.label,
						name: field.name.replace('metadata-', ''),
						type: field.type,
						value,
					});
				} else {
					body[field.name] = value;
				}
			}

			// patch beneficiary
			const reqPatchBeneficiary = await fetch(
				`${process.env.REACT_APP_API_URL}/entities/${beneficiary?.id}`,
				{
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
					body: JSON.stringify(body),
				}
			);

			const resPatchBeneficiary = await reqPatchBeneficiary.json();

			if (resPatchBeneficiary.error) {
				throw new Error(resPatchBeneficiary.error.message, {
					cause: {
						id: resPatchBeneficiary.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch
			if (refetchBeneficiaries) {
				refetchBeneficiaries();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.beneficiaryUpdated', {
					name: entries.name,
				}),
				severity: 'success',
			});
		} catch (error) {
			console.log(error);
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let msg = t('error.default');

			if (cause) {
				if (cause.id === 'ENTITY_UNAUTHORIZED_PATCH') {
					msg = t('error.unauthorized');
				}
			}

			setError(msg);
		} finally {
			// clear loading
			setIsLoading(false);
		}
	};

	// define default values for each field
	const fields: FormField[] = useMemo(() => {
		const defaultFields = ['idExternal', 'name'];

		if (beneficiary && form) {
			return form.fields.map((field) => {
				let defaultValue = '';

				// check if default field
				if (defaultFields.includes(field.name)) {
					defaultValue = String(beneficiary[field.name as keyof Entity]);
				} else {
					// find metadata by field
					const metadataItem = metadata?.find((m) => m.name === field.name);

					// redefine default value if metadata exists
					if (metadataItem) {
						defaultValue = String(metadataItem.value);
					}

					// redefine field name with metadata prefix
					field.name = `metadata-${field.name}`;
				}

				return {
					...field,
					defaultValue,
				};
			});
		}

		return [];
	}, [beneficiary, form, metadata]);

	const renderContent = () => {
		if (isLoadingBeneficiary || isLoadingForm) {
			return (
				<Grid item xs={12}>
					<Grid alignItems="center" container justifyContent="center">
						<Grid item>
							<Loading />
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return fields.map((field, i) => {
			return (
				<Grid item key={`field-${i}-${field.name}`} xs={12} md={6}>
					<DynamicField field={field} />
				</Grid>
			);
		});
	};

	if (!beneficiary) {
		return <></>;
	}

	return (
		<form id={idForm} onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={beneficiary?.name}
						id={`${idForm}-name`}
						label={t('name')}
						name="name"
						required={true}
					/>
				</Grid>
				{renderContent()}
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
