// react
import { Dispatch, Fragment, SetStateAction } from 'react';

// types
import { CsvField, CsvRow, Option } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Grid, InputLabel, SelectChangeEvent, Typography } from '@mui/material';

// fields
import { Select } from '../../fields/Select/Select';

// ui
import { TableHeaders } from '../../ui/TableHeaders/TableHeaders';

// theme
import { theme } from '../../../theme';

export interface FormTableFieldsProps {
	formId: string;
	fields: CsvField[];
	options: Option[];
	rows: CsvRow[];
	setFields: Dispatch<SetStateAction<CsvField[]>>;
	titleTotal?: string;
}

export const FormTableFields = ({
	formId,
	fields,
	options = [],
	rows,
	setFields,
	titleTotal,
}: FormTableFieldsProps) => {
	// hooks
	const { t } = useTranslation();

	const handleSelectField = (e: SelectChangeEvent<unknown>) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value as string;
		const field = fields.find((field) => field.name === fieldName);

		if (field) {
			// get up to 3 examples from beneficiaries
			const examples: string[] = [];
			const limit = rows.length >= 3 ? 3 : rows.length;

			// for number of examples
			for (let i = 0; i < limit; i++) {
				const example = rows[i][fieldValue];

				if (example) {
					examples.push(String(example));
				}
			}

			const updatedFields: CsvField[] = fields.map((field) => {
				if (field.name === fieldName) {
					return {
						...field,
						header: fieldValue,
						examples,
					};
				}
				return field;
			});

			setFields(updatedFields);
		}
	};

	const total = rows.length;

	return (
		<Box
			sx={(theme) => {
				return {
					borderColor: theme.palette.divider,
					borderRadius: 2,
					borderSize: 1,
					borderStyle: 'solid',
					borderWidth: 1,
				};
			}}>
			<Grid container>
				<Grid item xs={12}>
					<Box
						sx={(theme) => {
							return {
								borderBottomColor: theme.palette.divider,
								borderBottomStyle: 'solid',
								borderBottomWidth: 1,
							};
						}}>
						<TableHeaders
							color="grey"
							hasShadow={false}
							headers={[
								{
									GridProps: {
										xs: 3,
									},
									label: t('field'),
									value: 'field',
								},
								{
									GridProps: {
										xs: 4.5,
									},
									label: t('column'),
									value: 'column',
								},
								{
									GridProps: {
										xs: 4.5,
									},
									label: t('preview'),
									value: 'preview',
								},
							]}
						/>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Grid container>
						{fields.map((field, index) => {
							return (
								<Fragment key={`field-${index}-${field.name}`}>
									<Grid item xs={12}>
										<Box
											sx={(theme) => {
												return {
													borderBottomColor: theme.palette.divider,
													borderBottomStyle: 'solid',
													borderBottomWidth: 1,
													padding: theme.spacing(2, 0, 2, 2),
												};
											}}>
											<Grid container alignItems={'center'} spacing={2}>
												<Grid item xs={3}>
													<InputLabel
														htmlFor={`${formId}-${field.name}`}
														required={field.isRequired}
														sx={{ margin: 0 }}>
														{field.label}
													</InputLabel>
												</Grid>
												<Grid item xs={4.5}>
													<Select
														id={`${formId}-${field.name}`}
														hasNoneOption={!field.isRequired}
														helperText={field.helper}
														name={field.name}
														onChange={handleSelectField}
														options={options}
														required={field.isRequired}
													/>
												</Grid>
												<Grid item xs={4.5}>
													<Typography color="brand.grey.500" variant="caption">
														{field.examples.length ? `${field.examples.join(', ')}...` : '-'}
													</Typography>
												</Grid>
											</Grid>
										</Box>
									</Grid>
								</Fragment>
							);
						})}
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Box
						sx={(theme) => {
							return {
								padding: theme.spacing(2),
							};
						}}>
						<Typography fontWeight={600} display="inline">
							{total}
						</Typography>{' '}
						<Typography color={theme.palette.brand.grey[500]} display="inline">
							{titleTotal || t('total')}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
